import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupIcon from "@mui/icons-material/Group";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import SwapCallsOutlinedIcon from "@mui/icons-material/SwapCallsOutlined";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useState } from "react";
import { Link, To, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSidebarState,
  setSidebarOpen,
} from "../../data/store/slices/utilSlice";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "@mui/material";

type MenuItem = {
  id: number;
  itemName: string;
  icon: any;
  iconSelected: any;
  navLink: To;
};

interface ISideNavbar {}

const SideNavbar = (props: ISideNavbar) => {
  const lenderId: any = localStorage.getItem("lenderId");
  const openSidebar = useSelector(selectCurrentSidebarState);
  const [selectedItem, setSelectedItem] = useState<number>(1);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    {
      id: 1,
      itemName: "Dashboard",
      icon: <SpaceDashboardOutlinedIcon />,
      iconSelected: <SpaceDashboardIcon />,
      navLink: "/dashboard",
    },
    {
      id: 2,
      itemName: "Borrowers",
      icon: <GroupOutlinedIcon />,
      iconSelected: <GroupIcon />,
      navLink: "/borrowers",
    },
    {
      id: 3,
      itemName: "Accounts",
      icon: <PaymentsOutlinedIcon />,
      iconSelected: <PaymentsIcon />,
      navLink: "/accounts",
    },
    {
      id: 4,
      itemName: "Disputes",
      icon: <SwapCallsOutlinedIcon />,
      iconSelected: <SwapCallsIcon />,
      navLink: "/disputes",
    },
    // {
    //   id: 5,
    //   itemName: "Collections",
    //   icon: <CreditScoreOutlinedIcon />,
    //   iconSelected: <CreditScoreIcon />,
    //   navLink: "/collections",
    // },
    // {
    //   id: 6,
    //   itemName: "Discount",
    //   icon: <DiscountOutlinedIcon />,
    //   iconSelected: <DiscountIcon />,
    //   navLink: "",
    // },
    // {
    //   id: 7,
    //   itemName: "Reports",
    //   icon: <AssessmentOutlinedIcon />,
    //   iconSelected: <AssessmentIcon />,
    //   navLink: "",
    // },
  ];

  menuItems.push({
    id: 5,
    itemName: "Collections",
    icon: <CreditScoreOutlinedIcon />,
    iconSelected: <CreditScoreIcon />,
    navLink: "/collections",
  });

  // const settingsItem: MenuItem = {
  //   id: 8,
  //   itemName: "Settings",
  //   icon: <SettingsOutlinedIcon />,
  //   iconSelected: <SettingsIcon />,
  //   navLink: "",
  // };

  return (
    <div
      className={`h-screen lg:h-full ${
        openSidebar ? (isTabletOrMobile ? "w-screen" : "w-60") : "w-20"
      } duration-300 border-r bg-white`}
    >
      {!isTabletOrMobile && (
        <NavigateBeforeIcon
          className={`absolute cursor-pointer -right-4
        top-[40%] w-8 h-12 bg-white border rounded-lg shadow ${
          !openSidebar && "rotate-180"
        }`}
          onClick={() =>
            dispatch(setSidebarOpen({ sidebarOpen: !openSidebar }))
          }
        />
      )}
      <div className="h-full w-full flex flex-col lg:justify-between">
        <div className="mt-10 w-full">
          {menuItems.map((e: MenuItem, i) => {
            const selected = e.id === selectedItem;
            return (
              <Link
                key={i}
                className={`flex py-3 px-7 hover:text-primary hover:bg-primarySoft cursor-pointer ${
                  selected && "text-primary bg-primarySoft"
                } justify-center lg:justify-start`}
                to={e.navLink}
                onClick={() => {
                  setSelectedItem(e.id);
                  navigate(e.navLink);
                }}
              >
                <Tooltip title={e.itemName}>
                  {selected ? e.iconSelected : e.icon}
                </Tooltip>
                <span className={`${!openSidebar && "hidden"} duration-300`}>
                  &nbsp;&nbsp;&nbsp;{e.itemName}
                </span>
              </Link>
            );
          })}
        </div>

        {/* <div className="w-full">
          <p
            className={`flex py-3 px-7 mb-10 hover:text-primary hover:bg-primarySoft cursor-pointer ${
              selectedItem === settingsItem.id && "text-primary bg-primarySoft"
            } justify-center lg:justify-start`}
            onClick={() => {
              setSelectedItem(settingsItem.id);
            }}
          >
            {selectedItem === settingsItem.id
              ? settingsItem.iconSelected
              : settingsItem.icon}
            <span className={`${!openSidebar && "hidden"} duration-300`}>
              &nbsp;&nbsp;&nbsp;{settingsItem.itemName}
            </span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default SideNavbar;
