import { Form, Formik } from "formik";
import { profileSettingsSchema } from "../../../../data/validations/ValidationScemas";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Grid } from "@mui/material";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import {
  getProfileSettings,
  putProfileSettings,
} from "../../../../data/api/settingsAPI";
import { useEffect, useState } from "react";
import { profileSettings } from "../../../../data/models/types/profileSettings";
import CButton from "../../../../components/formComponents/CButton";
import CModal from "../../../../components/modal/CModal";
import ChangePassword from "./ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import {
  currentModalName,
  isModalOpen,
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IProfileSettings {}
interface IformValues {
  name: string;
  legalName: string;
  subdomain: string;
  email: string;
  phone: string;
}

const ProfileSettings = (props: IProfileSettings) => {
  const lender: any = localStorage.getItem("lenderName");
  const [pSettings, setSettings] = useState<profileSettings>();
  const isOpen: boolean = useSelector(isModalOpen);
  const modalName: string = useSelector(currentModalName);
  const dispatch: any = useDispatch();

  useEffect(() => {
    profileSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpen = (state: boolean) => {
    dispatch(setOpenModal({ modalName: modalName, openModel: state }));
  };

  const profileSettings = async () => {
    try {
      dispatch(setLoading(true));
      const res: any = await getProfileSettings({ lender });
      if (res) setSettings(res.profileSettings);
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const saveProfileSettings = async (values: IformValues) => {
    try {
      dispatch(setLoading(true));
      const res: any = await putProfileSettings({ lender, data: values });
      if (res) {
        setSettings(res.profileSettings);
        notification("Profile settings updated.", "success");
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <div className="mx-10 mt-10">
      <div className="relative bg-white rounded-t-2xl border border-slate-200">
        <div className="w-full px-6 py-2 bg-slate-100 rounded-tl-2xl rounded-tr-2xl justify-start items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <AccountCircleOutlinedIcon />
            <div className="text-zinc-900 text-xl font-semibold leading-9">
              Profile Settings
            </div>
          </div>
        </div>
        <div className="mx-10 my-4">
          <Formik
            initialValues={{
              name: pSettings?.name || "",
              legalName: pSettings?.legalName || "",
              subdomain: pSettings?.subdomain || "",
              email: pSettings?.email || "",
              phone: pSettings?.phone || "",
            }}
            enableReinitialize={true}
            validationSchema={profileSettingsSchema}
            onSubmit={async (values: IformValues) => {
              saveProfileSettings(values);
            }}
          >
            {({ errors }) => (
              <Form id="edit-borrower-form">
                <div className="w-full mb-10">
                  <div className="flex border-b-2 py-2 items-center">
                    <h3 className="text-lg font-semibold">Personal Details</h3>
                  </div>
                  <Grid container spacing={2} className="pt-4">
                    <Grid item xs={4}>
                      <InputField
                        className="mb-5"
                        placeholder="Enter name"
                        label="Name"
                        name="name"
                        errorText={errors.name}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        className="mb-5"
                        placeholder="Enter legal name"
                        label="Legal name"
                        name="legalName"
                        errorText={errors.legalName}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        className="mb-5"
                        placeholder="Enter subdomain"
                        label="Subdomain"
                        name="subdomain"
                        errorText={errors.subdomain}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="w-full mb-10">
                  <div className="flex border-b-2 py-2 items-center">
                    <h3 className="text-lg font-semibold">Contact Info</h3>
                  </div>
                  <Grid container spacing={2} className="pt-4">
                    <Grid item xs={4}>
                      <InputField
                        className="mb-5"
                        placeholder="Enter email"
                        label="Email"
                        name="email"
                        errorText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        className="mb-5"
                        placeholder="Enter mobile number"
                        label="Mobile Number"
                        name="phone"
                        errorText={errors.phone}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="w-full mb-10">
                  <div className="flex border-b-2 py-2 items-center">
                    <h3 className="text-lg font-semibold">Password</h3>
                  </div>
                  <div className="flex pt-4 justify-between">
                    <div className="text-black text-sm content-center leading-[18.62px]">
                      Click the button to change your password.
                    </div>
                    <CButton
                      type="text"
                      text="Change Password"
                      onClick={() => {
                        dispatch(
                          setOpenModal({
                            modalName: "change_password",
                            openModel: true,
                          })
                        );
                      }}
                      submit={false}
                    />
                  </div>
                </div>
                <div className="w-full text-end">
                  <CButton type="contained" text="Save" submit={true} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {modalName === "change_password" && (
        <CModal
          title="Change Password"
          body={<ChangePassword />}
          open={isOpen}
          setOpen={setOpen}
          size="sm"
          actionButton
          actionButtonText="Change Password"
          actionButtonForm={"change_password"}
          submit={true}
        />
      )}
    </div>
  );
};

export default ProfileSettings;
