import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { borrower } from "../../../../data/models/types/borrower";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import { Form, Formik } from "formik";
import { editBorrowerSchema } from "../../../../data/validations/ValidationScemas";
import { Grid } from "@mui/material";
import { updateBorrower, updateUser } from "../../../../data/api/borrowerAPI";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import JoyUIDatePickerField from "../../../../components/formComponents/fieldComponents/JoyUIDatePickerField";
import notification from "../../../../utils/toast";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CButton from "../../../../components/formComponents/CButton";
dayjs.extend(customParseFormat);

interface IEditBorrower {
  borrower?: borrower;
  refreshAccount: () => {};
}

interface IformValues {
  idNumber: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  dob: any | undefined;
  email: string | undefined;
  phone: string | undefined;
}

interface IformValues2 {
  addressLine1: string | undefined;
  city: string | undefined;
  country: string | undefined;
  zip: string | undefined;
}

const EditBorrower = ({ borrower, refreshAccount }: IEditBorrower) => {
  const lender: any = localStorage.getItem("lenderName");
  const dispatch = useDispatch();

  const updateBorrowerDetails = async (values: IformValues2) => {
    try {
      const res: any = await updateBorrower({
        lender,
        borrowerId: borrower?.publicId!,
        body: { ...values },
      });

      if (res.status === 200) {
        refreshAccount!();
        notification("Borrower details updated.", "success");
        dispatch(
          setOpenModal({ modalName: "edit-borrower", openModel: false })
        );
      } else {
        notification(res.data.message, "error");
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      notification(error.message, "error");
    }
  };

  const updateUserDetails = async (values: IformValues) => {
    try {
      const res: any = await updateUser({
        lender,
        borrowerId: borrower?.publicId!,
        userId: borrower?.userPublicId,
        body: { ...values, dob: dayjs(values.dob).format("DD/MM/YYYY") },
      });

      if (res.status === 200) {
        refreshAccount!();
        notification("Borrower details updated.", "success");
        dispatch(
          setOpenModal({ modalName: "edit-borrower", openModel: false })
        );
      } else {
        notification(res.data.message, "error");
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      notification(error.message, "error");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          idNumber: borrower?.idNumber,
          firstName: borrower?.firstName,
          lastName: borrower?.lastName,
          dob: dayjs(borrower?.dob, "DD/MM/YYYY"),
          email: borrower?.email,
          phone: borrower?.phone,
        }}
        validationSchema={editBorrowerSchema}
        onSubmit={async (value: IformValues) => {
          updateUserDetails(value);
        }}
      >
        {({ errors }) => (
          <Form id="edit-borrower">
            <div className="w-full mb-10">
              <div className="flex border-b-2 py-2 items-center">
                <AccountCircleOutlinedIcon className="mr-2" />{" "}
                <h3 className="text-lg font-semibold">Personal Details</h3>
              </div>
              <Grid container spacing={2} className="pt-4">
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter first name"
                    label="First Name"
                    name="firstName"
                    errorText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter last name"
                    label="Last Name"
                    name="lastName"
                    errorText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Emirates Id"
                    label="Emitares Id"
                    name="idNumber"
                    errorText={errors.idNumber}
                  />
                </Grid>
                <Grid item xs={3}>
                  <JoyUIDatePickerField
                    name="dob"
                    errorText={errors.dob?.toLocaleString()}
                    horizontal={false}
                    label="Date of Birth"
                  />
                </Grid>
              </Grid>
            </div>
            <div className="w-full mb-10">
              <div className="flex border-b-2 py-2 items-center">
                <PhoneIcon className="mr-2" />{" "}
                <h3 className="text-lg font-semibold">Contact Info</h3>
              </div>
              <Grid container spacing={2} className="pt-4">
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter email"
                    label="Email"
                    name="email"
                    errorText={errors.email}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter mobile number"
                    label="Mobile Number"
                    name="phone"
                    errorText={errors.phone}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="flex justify-end items-center">
              <CButton
                text={"Save Details"}
                type="contained"
                icon={<EditIcon />}
                form="edit-borrower"
                submit={true}
              />
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{
          addressLine1: borrower?.address.addressLine1,
          city: borrower?.address.city,
          country: borrower?.address.country,
          zip: borrower?.address.zip,
        }}
        validationSchema={editBorrowerSchema}
        onSubmit={async (value: IformValues2) => {
          updateBorrowerDetails(value);
        }}
      >
        {({ errors }) => (
          <Form id="edit-user">
            <div className="w-full">
              <div className="flex border-b-2 py-2 items-center">
                <RoomOutlinedIcon className="mr-2" />{" "}
                <h3 className="text-lg font-semibold">Address</h3>
              </div>
              <Grid container spacing={2} className="pt-4">
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter address"
                    label="Address"
                    name="addressLine1"
                    errorText={errors.addressLine1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter city"
                    label="City"
                    name="city"
                    errorText={errors.city}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter country"
                    label="Country"
                    name="country"
                    errorText={errors.country}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    className="mb-5"
                    placeholder="Enter zip code"
                    label="Zip Code"
                    name="zip"
                    errorText={errors.zip}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="flex justify-end items-center">
              <CButton
                text={"Save Address"}
                type="contained"
                icon={<EditIcon />}
                form="edit-user"
                submit={true}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditBorrower;
