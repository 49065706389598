import { Card, CircularProgress } from "@mui/material";
import { widgetData } from "../../data/models/types/widget";
import CTable from "../table/CTable";
import { convertAmount, createTableHeaders } from "../../utils/helperFunctions";
import { useSelector } from "react-redux";
import { isSkeleton } from "../../data/store/slices/utilSlice";

interface ITableChart {
  title?: string | null;
  data?: widgetData | null;
}

const TableChart = ({ title, data }: ITableChart) => {
  const headers = data?.metadata?.headers;
  const headerCells = createTableHeaders(headers);
  const isSkeletonOn = useSelector(isSkeleton);

  let rows: any = data?.dataSets;

  rows = rows.map((e: any) => ({ ...e, amount: convertAmount(e.amount) }));

  return (
    <>
      {!isSkeletonOn ? (
        <Card
          variant="outlined"
          className="flex flex-col grow shadow-md rounded-md mb-4 sm:mr-4 px-4 pt-4 pb-[53px]"
        >
          <h1 className="text-lg font-bold pb-4">{title}</h1>
          <CTable headCells={headerCells} rows={rows} pagination={false} />
        </Card>
      ) : (
        <Card
          variant="outlined"
          className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
        >
          <div className="flex justify-center items-center h-full">
            <CircularProgress size={65} color="primary" />
          </div>
        </Card>
      )}
    </>
  );
};

export default TableChart;
