import { useState } from "react";

interface ITableTopFilters {
  filters?: string[];
  filterAction?: any;
  setPage: (value: number) => void;
  setSize: (value: number) => void;
}

const TableTopFilters = ({
  filters,
  filterAction,
  setPage,
  setSize,
}: ITableTopFilters) => {
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  return (
    <>
      <div className="flex overflow-auto">
        {filters?.map((e, index) => (
          <div
            key={index}
            className={`
          flex flex-wrap content-center h-10 px-4 py-1 hover:cursor-pointer border ${
            index === selectedFilter
              ? "bg-[#C7DFF7] text-primary border-[#97C3F0]"
              : "bg-[#F0F4F8] text-[#555E68] border-[#e5e7eb]"
          } ${index === 0 && "rounded-tl-lg"} ${
              index === filters.length - 1 && "rounded-tr-lg"
            }`}
            onClick={() => {
              setSelectedFilter(index);
              setPage(0);
              setSize(10);
              filterAction && filterAction(index);
            }}
          >
            {e}
          </div>
        ))}
      </div>
      <div className="h-full border-b mb-2"></div>
    </>
  );
};

export default TableTopFilters;
