import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import { FormHelperText } from "@mui/joy";
import { Field } from "formik";

interface IHorizontalInputField {
  placeholder?: string;
  label?: string;
  subtext?: string;
  name?: string;
  className?: string;
  errorText?: string;
  endIcon?: any;
  type?: string;
  border?: boolean;
  rest?: any;
}

const HorizontalInputField = ({
  placeholder,
  label,
  subtext,
  name,
  errorText,
  endIcon,
  border = true,
  className,
  type,
  ...rest
}: IHorizontalInputField) => {
  return (
    <div className={`${className} w-full`}>
      <Field name={name}>
        {({ field, meta }: any) => (
          <FormControl>
            <div
              className={`h-12 px-4 py-2 rounded-md ${
                border && "border border-slate-300"
              }  justify-start items-center gap-6 inline-flex`}
            >
              <div className="grow shrink basis-0 h-8 justify-start items-center">
                <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                  {label}
                </div>
                <div className="text-gray-600 text-sm font-medium leading-[21px]">
                  {subtext}
                </div>
              </div>
              <div className="flex-col justify-start items-start inline-flex">
                <Input
                  {...field}
                  {...rest}
                  className={`h-8`}
                  placeholder={placeholder}
                  name={name}
                  type={type}
                  endDecorator={endIcon}
                  error={meta.touched && meta.error ? true : false}
                />
              </div>
            </div>
            {meta.touched && meta.error ? (
              <FormHelperText>
                <p className="text-[#C41C1C]">{errorText}</p>
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default HorizontalInputField;
