interface IActionConfirmation {
  icon: any;
  message: string;
}

const ActionConfirmation = ({ icon, message }: IActionConfirmation) => {
  return (
    <div className="w-full flex-col justify-center items-center gap-3 inline-flex">
      <div className="flex justify-center items-center w-[88px] h-[88px] bg-slate-100 rounded-full">
        {icon}
      </div>
      <div className="w-[400px] text-center text-neutral-700 text-sm font-medium font-['Inter'] leading-tight">
        {message}
      </div>
    </div>
  );
};

export default ActionConfirmation;
