import axios, { AxiosRequestConfig } from "axios";
import { ICreateRequest } from "../models/types/axiosCreateRequest";

const BASE_URL: string | undefined = process.env.REACT_APP_DEV_CORE_BASE_URL;

const baseRequest = async ({
  path,
  method,
  data,
  additionalHeaders,
}: ICreateRequest) => {
  try {
    const token = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const headers: {} = {
      "Content-Type": "application/json",
      ...token,
      ...additionalHeaders,
    };
    const url: string = BASE_URL + path;
    const options: AxiosRequestConfig<any> | undefined = {
      headers,
      withCredentials: true,
    };

    let response = null;
    switch (method) {
      case "get":
        response = await axios.get(url, options);
        break;
      case "post":
        response = await axios.post(url, data, options);
        break;
      case "put":
        response = await axios.put(url, data, options);
        break;

      default:
        break;
    }

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const createRequest = async ({
  path,
  method,
  data,
  additionalHeaders,
}: ICreateRequest) => {
  try {
    let res = await baseRequest({
      path,
      method,
      data,
      additionalHeaders,
    });

    if (res?.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      const lenderName = localStorage.getItem("lenderName");
      if (refreshToken) {
        const refreshResult = await baseRequest({
          path: `/auth/${lenderName}/refresh-token`,
          method: "post",
          data: { refreshToken },
        });

        if (refreshResult?.status === 200) {
          localStorage.setItem("token", refreshResult?.data.token);
          res = await baseRequest({
            path,
            method,
            data,
            additionalHeaders,
          });
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
        }
      }
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};
