import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { widgetData } from "../../data/models/types/widget";
import { Card, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { isSkeleton } from "../../data/store/slices/utilSlice";
import { convertAmount } from "../../utils/helperFunctions";

type IBarChart = {
  title?: string | null;
  labelXAxis?: string | null;
  labelYAxis?: string | null;
  data?: widgetData | null;
};

const BarChart = ({ title, labelYAxis, labelXAxis, data }: IBarChart) => {
  const isSkeletonOn = useSelector(isSkeleton);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
      align: "left",
    },
    xAxis: {
      categories: data?.dataSets?.map((e) => e.label),
      title: {
        text: labelXAxis,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: labelYAxis,
      },
      stackLabels: {
        enabled: false,
      },
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> Age Group:</b> {point.category}<br/>' +
        "<span >\u25CF</span> <b> Debt:</b> {point.y: .0f}<br/>",
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: "{point.y: , .0f}",
        },
      },
    },
    series: [
      {
        data: data?.dataSets?.map((e) => e.value),
        showInLegend: false,
      },
    ],
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };
  return (
    <>
      {!isSkeletonOn ? (
        <Card
          variant="outlined"
          className="flex grow shadow-md rounded-md mb-4 sm:mr-4 p-4"
        >
          <div className="w-full">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
        >
          <div className="flex justify-center items-center h-full">
            <CircularProgress size={65} color="primary" />
          </div>
        </Card>
      )}
    </>
  );
};

export default BarChart;
