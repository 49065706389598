import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { borrower } from "../../../../data/models/types/borrower";
import { Tooltip } from "@mui/material";

interface IBorrowerDetails {
  borrower?: borrower;
}

const BorrowerDetails = ({ borrower }: IBorrowerDetails) => {
  return (
    <>
      <div className="w-full mb-10">
        <div className="flex border-b-2 py-2 items-center">
          <AccountCircleOutlinedIcon className="mr-2" />{" "}
          <h3 className="text-lg font-semibold">Personal Details</h3>
        </div>
        <ul className="flex py-2 items-center justify-between">
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">First Name</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.firstName !== null ? borrower?.firstName : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Middle Name</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.middleName !== null ? borrower?.middleName : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Last Name</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.lastName !== null ? borrower?.lastName : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Emirates Id</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.idNumber !== null ? borrower?.idNumber : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">DOB</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.dob !== null ? borrower?.dob : "-"}
            </p>
          </li>
        </ul>
      </div>
      <div className="w-full mb-10">
        <div className="flex border-b-2 py-2 items-center">
          <PhoneIcon className="mr-2" />{" "}
          <h3 className="text-lg font-semibold">Contact Info</h3>
        </div>
        <ul className="flex py-2 items-center ">
          <li className="py-2 px-4 mr-10">
            <h3 className="text-base font-semibold">Primary Email</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.email !== null ? borrower?.email : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Primary Mobile Number</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.phone !== null ? "+" + borrower?.phone : "-"}
            </p>
          </li>
        </ul>
      </div>
      <div className="w-full">
        <div className="flex border-b-2 py-2 items-center">
          <RoomOutlinedIcon className="mr-2" />{" "}
          <h3 className="text-lg font-semibold">Address</h3>
        </div>
        <ul className="flex py-2 items-center justify-between">
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Address</h3>
            <Tooltip
              title={
                borrower?.address.addressLine1 !== null
                  ? borrower?.address.addressLine1
                  : "-"
              }
              sx={{ maxWidth: "20rem" }}
            >
              <p className="text-lg text-[#4a4e50] truncate w-40">
                {borrower?.address.addressLine1 !== null
                  ? borrower?.address.addressLine1
                  : "-"}
              </p>
            </Tooltip>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">City</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.address.city !== null ? borrower?.address.city : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Country</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.address.country !== null
                ? borrower?.address.country
                : "-"}
            </p>
          </li>
          <li className="py-2 px-4">
            <h3 className="text-base font-semibold">Zip Code</h3>
            <p className="text-lg text-[#4a4e50]">
              {borrower?.address.zip !== null ? borrower?.address.zip : "-"}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BorrowerDetails;
