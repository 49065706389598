import FormControl from "@mui/joy/FormControl";
import {
  FormHelperText,
  FormLabel,
  Input,
  Switch,
  Theme,
  switchClasses,
} from "@mui/joy";
import { Field } from "formik";
import { useEffect, useState } from "react";

interface ISwitchField {
  label?: string;
  text?: string;
  name?: string;
  subLabel?: string;
  subtext?: string;
  subName?: string;
  subType?: string;
  isLink?: boolean;
  className?: string;
  errorText?: string;
  horizontal?: boolean;
  horizontalBorder?: boolean;
  redirect?: boolean;
  showLinkDefault?: boolean;
  rest?: any;
}

const SwitchField = ({
  label,
  text,
  name,
  subLabel,
  subtext,
  subName = "link",
  subType,
  isLink = true,
  errorText,
  horizontal,
  horizontalBorder = true,
  redirect,
  className,
  showLinkDefault = false,
  ...rest
}: ISwitchField) => {
  const [showLinkField, setShowLinkField] = useState<boolean>(false);

  useEffect(() => {
    setShowLinkField(showLinkDefault);
  }, [showLinkDefault]);

  return (
    <div className={`${className} ${horizontal && "w-full"}`}>
      <div
        className={`px-4 py-2 rounded-md ${
          horizontalBorder && "border border-slate-300"
        }`}
      >
        {horizontal ? (
          <>
            <Field name={name}>
              {({ field, form, meta }: any) => (
                <FormControl>
                  <div
                    className={`justify-start items-center gap-6 inline-flex ${
                      showLinkField && "mb-2"
                    }`}
                  >
                    <div className="grow shrink basis-0 h-8 justify-start items-center">
                      <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                        {label}
                      </div>
                      <div className="text-gray-600 text-sm font-medium leading-[21px]">
                        {text}
                      </div>
                    </div>
                    <Switch
                      {...field}
                      {...rest}
                      checked={field.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        form.setFieldValue(name, event.target.checked);
                        if (redirect === true) {
                          setShowLinkField(event.target.checked);
                        }
                      }}
                      sx={(theme: Theme) => ({
                        display: "inherit",
                        "--Switch-thumbShadow": `0 0 0 1px ${theme.vars.palette.background.level3}, 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 1px 2px 0px rgb(0 0 0 / 0.3)`,
                        "--Switch-thumbSize": "18px",
                        "--Switch-trackWidth": "36px",
                        "--Switch-trackHeight": "14px",
                        "--Switch-trackBackground":
                          "rgb(107 114 128 / var(--tw-bg-opacity))",
                        "&:hover": {
                          "--Switch-trackBackground":
                            "rgb(107 114 128 / var(--tw-bg-opacity))",
                        },
                        [theme.getColorSchemeSelector("dark")]: {
                          "--Switch-trackBackground": "rgba(255 255 255 / 0.4)",
                        },
                        [`&.${switchClasses.checked}`]: {
                          "--Switch-trackBackground": "#0B6BCB",
                          "&:hover": {
                            "--Switch-trackBackground": "#0B6BCB",
                          },
                        },
                      })}
                    />
                  </div>

                  {meta.touched && meta.error ? (
                    <FormHelperText>
                      <p className="text-[#C41C1C]">{errorText}</p>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Field>
            {showLinkField && (
              <Field name={subName}>
                {({ field, meta }: any) => (
                  <FormControl>
                    {!isLink ? (
                      <div
                        className={`h-12 py-2 rounded-md justify-start items-center inline-flex`}
                      >
                        <div className="grow shrink basis-0 h-8 justify-start items-center">
                          <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                            {subLabel}
                          </div>
                          <div className="text-gray-600 text-sm font-medium leading-[21px]">
                            {subtext}
                          </div>
                        </div>
                        <div className="flex-col justify-start items-start inline-flex">
                          <Input
                            {...field}
                            {...rest}
                            className={`h-10`}
                            placeholder={"Enter value"}
                            type={subType}
                            name={subName}
                            error={meta.touched && meta.error ? true : false}
                          />
                        </div>
                      </div>
                    ) : (
                      <Input
                        {...field}
                        {...rest}
                        className={`h-10`}
                        placeholder={"Enter link"}
                        name={"link"}
                        error={meta.touched && meta.error ? true : false}
                      />
                    )}
                    {meta.touched && meta.error ? (
                      <FormHelperText>
                        <p className="text-[#C41C1C]">{errorText}</p>
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            )}
          </>
        ) : (
          <Field name={name}>
            {({ field, form, meta }: any) => (
              <FormControl>
                <FormLabel>{label}</FormLabel>
                <Switch
                  {...field}
                  {...rest}
                  checked={field.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    form.setFieldValue(name, event.target.checked);
                  }}
                  sx={(theme: Theme) => ({
                    display: "inherit",
                    "--Switch-thumbShadow": `0 0 0 1px ${theme.vars.palette.background.level3}, 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 1px 2px 0px rgb(0 0 0 / 0.3)`,
                    "--Switch-thumbSize": "18px",
                    "--Switch-trackWidth": "36px",
                    "--Switch-trackHeight": "14px",
                    "--Switch-trackBackground":
                      "rgb(107 114 128 / var(--tw-bg-opacity))",
                    "&:hover": {
                      "--Switch-trackBackground":
                        "rgb(107 114 128 / var(--tw-bg-opacity))",
                    },
                    [theme.getColorSchemeSelector("dark")]: {
                      "--Switch-trackBackground": "rgba(255 255 255 / 0.4)",
                    },
                    [`&.${switchClasses.checked}`]: {
                      "--Switch-trackBackground": "#0B6BCB",
                      "&:hover": {
                        "--Switch-trackBackground": "#0B6BCB",
                      },
                    },
                  })}
                />
                {meta.touched && meta.error ? (
                  <FormHelperText>
                    <p className="text-[#C41C1C]">{errorText}</p>
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          </Field>
        )}
      </div>
    </div>
  );
};

export default SwitchField;
