import { createSlice } from "@reduxjs/toolkit";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  statsUrl: string;
}

interface IAuthSlice {
  user: User | null;
  token: string | null;
}

interface IPayload {
  userResponse: User | null;
  token: string | null;
}

const initialState: IAuthSlice = { user: null, token: null };

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { userResponse, token }: IPayload = action.payload;
      state.user = userResponse;
      state.token = token;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.token;
