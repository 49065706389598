import { Form, Formik } from "formik";
import { collectionsSchema } from "../../../data/validations/ValidationScemas";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import HorizontalInputField from "../../../components/formComponents/fieldComponents/HorizontalInputField";
import SwitchField from "../../../components/formComponents/fieldComponents/SwitchField";
import { Divider } from "@mui/material";
import {
  getLenderConfigs,
  updateLenderConfigs,
} from "../../../data/api/collectionsAPI";
import { useEffect, useState } from "react";
import { collectionSettings } from "../../../data/models/response/collectionSettings";
// import DropZone from "../../../components/dropzone/Dropzone";
import CButton from "../../../components/formComponents/CButton";
import notification from "../../../utils/toast";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../data/store/slices/utilSlice";
import { setLoadingWithDelay } from "../../../data/store/thunk/utilThunk";
import JoyUIDatePickerField from "../../../components/formComponents/fieldComponents/JoyUIDatePickerField";
import dayjs from "dayjs";
import {
  getDateInDashFormat,
  getFormattedDayJSDate,
} from "../../../utils/helperFunctions";
import SelectField from "../../../components/formComponents/fieldComponents/SelectField";

interface ICollections {}
interface IformValues {
  paymentExtensionSettings: boolean;
  schedulePayment: number;
  paymentPlanSettings: boolean;
  maximumInstallments: number;
  variableInstallments: boolean;
  largeDebtsAbove: number;
  maximumInstallmentsLD: number;
  paymentPlanFirstInstalmentDaysAllowed: number;
  paymentPlanFirstInstalmentDateAllowed: boolean;
  redirectionLink: string;
  allowRedirection: boolean;
  allowedByDate: boolean;
  maxStartDays: any;
  allowedCards: string[] | null;
}

const cardOptions = [
  { label: "All", value: "all" },
  {
    label: "Credit Card",
    value: "credit_card",
  },
  {
    label: "Debit Card",
    value: "debit_card",
  },
  {
    label: "Prepaid Card",
    value: "prepaid_card",
  },
];

const Collections = (props: ICollections) => {
  const lender: any = localStorage.getItem("lenderName");
  const [configs, setConfig] = useState<collectionSettings>();
  // const [file, setFile] = useState<File | null>(null);
  const dispatch: any = useDispatch();

  // const timeStamp = (date: any) =>
  //   new Date(date.getTime())
  //     .toISOString()
  //     .replace(/[\-\.\:ZT]/g, "")
  //     .substr(2, 10);

  // const s3Config: any = {
  //   bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  //   dirName: process.env.REACT_APP_S3_LENDER_LOGO_DIR_NAME,
  //   region: process.env.REACT_APP_S3_REGION,
  //   accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  //   secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  // };

  useEffect(() => {
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfigs = async () => {
    try {
      dispatch(setLoadingWithDelay(true));
      const res: any = await getLenderConfigs({ lender });
      setConfig(res[0]);
      dispatch(setLoadingWithDelay(false));
    } catch (error) {
      notification("Error getting configurations", "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   setFile(acceptedFiles[0]);
  // }, []);

  // const onDelete = () => {
  //   setFile(null);
  // };

  // const handleUpload = async () => {
  //   try {
  //     const s3 = new ReactS3Client(s3Config);
  //     const filename = `${lender}_logo_${timeStamp(new Date(Date.now()))}`;

  //     if (file) {
  //       await s3.uploadFile(file, filename);
  //       return filename;
  //     }
  //   } catch (err) {
  //     notification("Error uploading file", "error");
  //   }
  // };

  const handleSubmit = async (values: IformValues) => {
    try {
      dispatch(setLoading(true));
      // const filename = await handleUpload();
      const res: any = await updateLenderConfigs({
        lender,
        data: {
          // logoUrl: filename + ".png",
          payLaterAllowed: values.paymentExtensionSettings,
          payLaterDaysAllowed: values.schedulePayment,
          paymentPlanAllowed: values.paymentPlanSettings,
          paymentPlanMonthsAllowed: values.maximumInstallments,
          extendedInstallmentsAllowed: values.variableInstallments,
          extendedInstallmentsAmount: values.largeDebtsAbove,
          extendedInstallmentsMonthsAllowed: values.maximumInstallmentsLD,
          paymentPlanFirstInstalmentDaysAllowed:
            values.paymentPlanFirstInstalmentDaysAllowed,
          paymentPlanFirstInstalmentDateAllowed:
            values.paymentPlanFirstInstalmentDateAllowed,
          allowRedirection: values.allowRedirection,
          redirectionLink: values.redirectionLink,
          payLaterAllowedByDate: values.allowedByDate,
          payLaterAllowedDate: getDateInDashFormat(values.maxStartDays),
          allowedCards: values.allowedCards?.includes("all")
            ? null
            : values.allowedCards?.join(","),
        },
      });

      setConfig(res.lenderConfig);

      dispatch(setLoadingWithDelay(false));
      notification("Collection settings updated.", "success");
    } catch (err) {
      notification("Error updating settings", "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <div className="mx-10 mt-10">
      <div className="relative bg-white rounded-t-2xl border border-slate-200">
        <div className="w-full px-6 py-2 bg-slate-100 rounded-tl-2xl rounded-tr-2xl justify-start items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <CreditScoreIcon />
            <div className="text-zinc-900 text-xl font-semibold leading-9">
              Collection Settings
            </div>
          </div>
        </div>
        <div className="mx-10 my-4">
          <Formik
            initialValues={{
              paymentExtensionSettings: configs?.payLaterAllowed || false,
              schedulePayment: configs?.payLaterDaysAllowed || 0,
              paymentPlanSettings: configs?.paymentPlanAllowed || false,
              maximumInstallments: configs?.paymentPlanMonthsAllowed || 0,
              variableInstallments:
                configs?.extendedInstallmentsAllowed || false,
              largeDebtsAbove: configs?.extendedInstallmentsAmount || 0,
              maximumInstallmentsLD:
                configs?.extendedInstallmentsMonthsAllowed || 0,
              paymentPlanFirstInstalmentDaysAllowed:
                configs?.paymentPlanFirstInstalmentDaysAllowed || 0,
              paymentPlanFirstInstalmentDateAllowed:
                configs?.paymentPlanFirstInstalmentDateAllowed || false,
              allowRedirection: configs?.allowRedirection || false,
              redirectionLink: configs?.redirectionLink || "",
              allowedByDate: configs?.payLaterAllowedByDate || false,
              maxStartDays:
                getFormattedDayJSDate(configs?.payLaterAllowedDate) ||
                dayjs("01/01/0001"),
              allowedCards:
                (configs?.allowedCards &&
                  (configs?.allowedCards as string).split(",")) ||
                null,
            }}
            enableReinitialize={true}
            validationSchema={collectionsSchema}
            onSubmit={async (values: IformValues) => {
              handleSubmit(values);
            }}
          >
            {({ errors, values }) => (
              <Form>
                <SwitchField
                  label="Payment Extension Settings"
                  name="paymentExtensionSettings"
                  horizontal={true}
                  horizontalBorder={false}
                />
                <SwitchField
                  label="Allowed by date"
                  text="Choose between date or number of days"
                  name="allowedByDate"
                  horizontalBorder={false}
                  horizontal={true}
                />
                <JoyUIDatePickerField
                  name="maxStartDays"
                  errorText="Invalid date"
                  horizontal
                  border={false}
                  label="Set the maximum scheduling limit for users"
                />
                <HorizontalInputField
                  name="schedulePayment"
                  label="Schedule Payment"
                  subtext="Timeframe for borrowers to schedule a payment"
                  placeholder="Enter no of days"
                  border={false}
                />
                <Divider className="my-8" />
                <SwitchField
                  label="Payment Plan Settings"
                  name="paymentPlanSettings"
                  horizontal={true}
                  horizontalBorder={false}
                />

                <HorizontalInputField
                  name="maximumInstallments"
                  label="Maximum Installments"
                  subtext="Allowed for payment plan"
                  placeholder="Choose months"
                  type="number"
                  border={false}
                />

                <SwitchField
                  label="First Installment Date"
                  name="paymentPlanFirstInstalmentDateAllowed"
                  subLabel="Max Days Allowed"
                  subtext="How many days ahead allowed"
                  subName="paymentPlanFirstInstalmentDaysAllowed"
                  isLink={false}
                  horizontal={true}
                  horizontalBorder={false}
                  redirect={true}
                  showLinkDefault={
                    configs
                      ? configs.paymentPlanFirstInstalmentDateAllowed
                      : false
                  }
                />

                <Divider className="my-8" />

                <SwitchField
                  label="Variable Installments"
                  name="variableInstallments"
                  horizontal={true}
                  horizontalBorder={false}
                />
                <HorizontalInputField
                  name="largeDebtsAbove"
                  label="Large Debts Above"
                  placeholder="Enter amount"
                  border={false}
                />
                <HorizontalInputField
                  name="maximumInstallmentsLD"
                  label="Maximum Installments"
                  subtext="For large debts"
                  placeholder="Choose months"
                  type="number"
                  border={false}
                />
                {/* <Divider className="my-4" />
                <div>
                  <div className="ml-4 mb-4 text-zinc-900 text-lg font-semibold leading-[27px]">
                    Add Business Logo
                  </div>
                  <div className="w-full justify-center">
                    <DropZone
                      onDrop={onDrop}
                      text="PNG - Preferable size 180*180 pixels"
                      acceptedFormat={{
                        "image/*": [".png"],
                      }}
                      onDelete={onDelete}
                      fileLimit={1}
                      maxSize={10485760}
                      multiple={false}
                    />
                  </div>
                </div> */}
                <Divider className="my-8" />
                <SwitchField
                  label="Allow Redirection"
                  name="allowRedirection"
                  subLabel="Redirection Link"
                  subtext="Link to redirect borrowers"
                  subName="redirectionLink"
                  isLink={false}
                  horizontal={true}
                  horizontalBorder={false}
                  redirect={true}
                  showLinkDefault={configs ? configs.allowRedirection : false}
                />

                <SelectField
                  options={cardOptions}
                  label="Allowed Cards"
                  name="allowedCards"
                  className="min-w-52"
                  multiple={true}
                  horizontal={true}
                />
                <div className="w-full text-end mt-12">
                  <CButton
                    submit={true}
                    text="Save"
                    type="contained"
                    className="w-32"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Collections;
