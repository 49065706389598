import { Form, Formik } from "formik";
import { changePasswordSchema } from "../../../../../data/validations/ValidationScemas";
import InputField from "../../../../../components/formComponents/fieldComponents/InputField";
import { Divider } from "@mui/joy";
import { changePassword } from "../../../../../data/api/settingsAPI";
import notification from "../../../../../utils/toast";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setOpenModal,
} from "../../../../../data/store/slices/utilSlice";
import { setLoadingWithDelay } from "../../../../../data/store/thunk/utilThunk";

interface IChangePassword {}
interface IFormValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword = (props: IChangePassword) => {
  const lender: any = localStorage.getItem("lenderName");
  const dispatch: any = useDispatch();

  const handleSubmit = async (values: IFormValues) => {
    try {
      dispatch(setLoading(true));
      await changePassword({
        lender,
        data: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      });
      dispatch(setLoadingWithDelay(false));
      notification("Password changed successfully", "success");
      dispatch(
        setOpenModal({
          modalName: "change_password",
          openModel: false,
        })
      );
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      enableReinitialize={true}
      validationSchema={changePasswordSchema}
      onSubmit={async (values: IFormValues) => {
        handleSubmit(values);
      }}
    >
      {({ errors }) => (
        <Form id="change_password">
          <div className="flex-col justify-center items-center flex w-full">
            <InputField
              className="w-full"
              placeholder="Enter password"
              label="Old Password"
              name="oldPassword"
              password={true}
              errorText={errors.oldPassword}
            />
            <Divider sx={{ marginTop: "1rem" }} />

            <InputField
              className="w-full mt-2"
              placeholder="Enter password"
              label="New Password"
              name="newPassword"
              password={true}
              errorText={errors.newPassword}
            />

            <InputField
              className="w-full mt-2"
              placeholder="Enter password"
              label="Confirm Password"
              name="confirmNewPassword"
              password={true}
              errorText={errors.confirmNewPassword}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
