import {
  disputeRequest,
  disputeTypesRequest,
  disputeTypesUpdateRequest,
  updateDisputeRequest,
} from "../models/request/disputeRequest";
import { disputesRequest } from "../models/request/disputesRequest";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";

export const getDispiutes = async ({
  size,
  page,
  lender,
  filter,
  searchString,
}: disputesRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDispiutesByAccountId = async ({
  size,
  page,
  lender,
  filter,
  accountId,
  searchString,
}: disputesRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/account/${accountId}?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDispiute = async ({ lender, id }: disputeRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/${id}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateDispiute = async ({
  lender,
  id,
  body,
}: updateDisputeRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/${id}`;

    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data: body,
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDispiuteTypes = async ({ lender }: disputeTypesRequest) => {
  try {
    const path: string = `/lenders/${lender}/dispute-types/all`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateDispiuteTypes = async ({
  lender,
  body,
}: disputeTypesUpdateRequest) => {
  try {
    const path: string = `/lenders/${lender}/dispute-types/all`;

    const reqObj: ICreateRequest = {
      path,
      method: "post",
      data: body,
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};
