import CModal from "../../../../components/modal/CModal";
import PayInFull from "../actionsModals/PayInFull";
import SchedulePayment from "../actionsModals/SchedulePayment";
import PaymentPlan from "../actionsModals/PaymentPlan";
import ChangeStatus from "../actionsModals/ChangeStatus";
import ActionConfirmation from "../actionsModals/ActionConfirmation";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  currentModalName,
  isModalOpen,
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import { updateAccount } from "../../../../data/api/accountAPI";
import notification from "../../../../utils/toast";
interface IConfigModals {
  amount?: number;
  accountId: string;
  lender: string;
  borrowerId: string;
  status: number;
  refreshAccount?: () => void;
}

const ConfigModals = ({
  lender,
  accountId,
  borrowerId,
  amount,
  status,
  refreshAccount,
}: IConfigModals) => {
  const isOpen: boolean = useSelector(isModalOpen);
  const modalName: string = useSelector(currentModalName);
  const dispatch = useDispatch();

  const setOpen = (state: boolean) => {
    dispatch(setOpenModal({ modalName: modalName, openModel: state }));
  };

  const updateStatus = async (status: number) => {
    try {
      await updateAccount({
        lender,
        accountId,
        borrowerId,
        body: { status },
      });

      refreshAccount!();
      dispatch(setOpenModal({ modalName: modalName, openModel: false }));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  const setModal = () => {
    switch (modalName) {
      case "pay_in_full":
        return (
          <CModal
            title="Pay in Full"
            body={
              <PayInFull
                amount={amount}
                lender={lender}
                accountId={accountId}
                borrowerId={borrowerId}
              />
            }
            icon={<AccountBalanceWalletOutlinedIcon />}
            open={isOpen}
            setOpen={setOpen}
            size="md"
            actionButton
            actionButtonText="Save"
            actionButtonForm={"pay_in_full"}
            submit={true}
          />
        );
      case "schedule_payment":
        return (
          <CModal
            title="Schedule Payment"
            body={
              <SchedulePayment
                amount={amount}
                lender={lender}
                accountId={accountId}
                borrowerId={borrowerId}
              />
            }
            icon={<EventOutlinedIcon />}
            open={isOpen}
            setOpen={setOpen}
            size="md"
            actionButton
            actionButtonText="Save"
            actionButtonForm={"schedule_payment"}
            submit={true}
          />
        );
      case "payment_plan":
        return (
          <CModal
            title="Payment Plan"
            body={
              <PaymentPlan
                amount={amount}
                lender={lender}
                accountId={accountId}
                borrowerId={borrowerId}
              />
            }
            icon={<EventAvailableOutlinedIcon />}
            open={isOpen}
            setOpen={setOpen}
            size="md"
            actionButton
            actionButtonText="Save"
            actionButtonForm={"payment_plan"}
            submit={true}
          />
        );
      case "pause_collection":
        return (
          <CModal
            title="Pause Collections"
            body={
              <ActionConfirmation
                icon={
                  <PauseCircleOutlineIcon className="w-16 h-16 text-gray-600" />
                }
                message="By pausing collections, you can temporarily suspend any ongoing collection efforts on specific accounts."
              />
            }
            open={isOpen}
            setOpen={setOpen}
            size="xs"
            varient="confirmation"
            actionButton
            actionButtonText="Yes, Pause Collections"
            actionButtonFunc={() => {
              updateStatus(14);
            }}
            closeButton
            closeButtonText="Cancel"
            closeButtonFunc={() => {
              setOpen(false);
            }}
          />
        );
      case "withdraw_account":
        return (
          <CModal
            title="Withdraw Account"
            body={
              <ActionConfirmation
                icon={
                  <PersonOffOutlinedIcon className="w-16 h-16 text-gray-600" />
                }
                message="When you withdraw an account, all history and activity associated with
          this account will be permanently deleted within 24 hours."
              />
            }
            open={isOpen}
            setOpen={setOpen}
            size="xs"
            varient="confirmation"
            actionButton
            actionButtonText="Yes, Withdraw Account"
            actionButtonFunc={() => {
              updateStatus(12);
            }}
            closeButton
            closeButtonText="Cancel"
            closeButtonFunc={() => {
              setOpen(false);
            }}
          />
        );
      case "change_status":
        return (
          <CModal
            title="Change Status"
            body={
              <ChangeStatus
                status={status}
                lender={lender}
                accountId={accountId}
                borrowerId={borrowerId}
                refreshAccount={refreshAccount}
              />
            }
            icon={<LoopOutlinedIcon />}
            open={isOpen}
            setOpen={setOpen}
            size="sm"
            actionButton
            actionButtonText="Save"
            actionButtonForm={"change_status"}
            submit={true}
          />
        );

      default:
        return <></>;
    }
  };

  return <div>{setModal()}</div>;
};

export default ConfigModals;
