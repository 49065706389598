import { loginRequest } from "../models/request/loginRequest";
import { loginResponse } from "../models/response/loginResponse";
import { createRequest } from "./axios";

export const login = async (
  credentials: loginRequest,
  lenderName: string | undefined
) => {
  const path: string = `/auth/${lenderName}/login`;
  const res: any = await createRequest({
    path,
    method: "post",
    data: credentials,
  });
  const loginResponse: loginResponse = res.data;
  return loginResponse;
};
