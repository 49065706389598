import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AllBorrowers from "./scenes/modules/borrowers/allBorrowers";
import Borrower from "./scenes/modules/borrowers/borrower";
import Login from "./scenes/login";
import RequiresAuth from "./components/commonComponents/RequiresAuth";
import Dashboard from "./scenes/modules/dashboard";
import AllAccounts from "./scenes/modules/accounts/allAccounts";
import Account from "./scenes/modules/accounts/account";
import Disputes from "./scenes/modules/disputes";
import Loading from "./components/commonComponents/Loading";
import { useSelector } from "react-redux";
import { isLoading } from "./data/store/slices/utilSlice";
import Collections from "./scenes/modules/collections";
import ProfileSettings from "./scenes/modules/settings/profileSettings";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

function App() {
  const loading = useSelector(isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token && location.pathname.includes("/l/")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="h-screen">
        <Routes>
          <Route path="/l/:lenderName" element={<Login />} />

          <Route element={<RequiresAuth />}>
            <Route path="/" element={<Navigate to={"/dashboard"} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/borrowers" element={<AllBorrowers />} />
            <Route path="/borrower" element={<Borrower />} />
            <Route path="/accounts" element={<AllAccounts />} />
            <Route path="/account" element={<Account />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/profile-settings" element={<ProfileSettings />} />
            <Route path="/disputes" element={<Disputes />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
