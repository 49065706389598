import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { widgetData } from "../../data/models/types/widget";
import VariablePie from "highcharts/modules/variable-pie.js";
import { Card, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { isSkeleton } from "../../data/store/slices/utilSlice";
VariablePie(Highcharts);
interface IPieChart {
  title?: string | null;
  data?: widgetData | null;
}

const PieChart = ({ data, title }: IPieChart) => {
  const isSkeletonOn = useSelector(isSkeleton);

  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: title,
      align: "left",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "<b>{point.name} Debt: </b>{point.y:.0f}<br/>" +
        "<b>Percentage:</b> {point.percentage:.1f} %<br />",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        size: "100%",
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "20%",
        zMin: 0,
        name: "countries",
        borderRadius: 5,
        data: data?.dataSets?.map((e) => ({
          name: e.label,
          y: e.value,
          z: e.value,
        })),
      },
    ],
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <>
      {!isSkeletonOn ? (
        <Card
          variant="outlined"
          className="flex grow shadow-md rounded-md mb-4 sm:mr-4 p-4"
        >
          <div className="w-full">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
        >
          <div className="flex justify-center items-center h-full">
            <CircularProgress size={65} color="primary" />
          </div>
        </Card>
      )}
    </>
  );
};

export default PieChart;
