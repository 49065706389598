import { Form, Formik } from "formik";
import CButton from "../../../../components/formComponents/CButton";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import * as Yup from "yup";
import TextAreaField from "../../../../components/formComponents/fieldComponents/TextArea";
import CheckboxField from "../../../../components/formComponents/fieldComponents/CheckboxField";
import {
  getDispiuteTypes,
  updateDispiuteTypes,
} from "../../../../data/api/disputeAPI";
import { disputeType } from "../../../../data/models/types/disputeType";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../data/store/slices/utilSlice";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";
import notification from "../../../../utils/toast";

interface IDisputeReasons {}

const DispiuteReasons = (props: IDisputeReasons) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const lenderId: any = localStorage.getItem("lenderId");

  const [disputeTypes, setDisputeTypes] = useState<any[]>([]);
  const [index, setIndex] = useState<number | null>(null);
  const [initialValues, setInitialValues] = useState<any>(null);
  const dispatch: any = useDispatch();

  useEffect(() => {
    getAllDisputeTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [initialValues]);

  const getAllDisputeTypes = async () => {
    try {
      dispatch(setLoading(true));

      const res = await getDispiuteTypes({
        lender: lenderName,
      });

      if (res) {
        const obj: any = {};

        res.forEach((type: disputeType, index: number) => {
          obj[`reason-${index}`] = type.reason;
          obj[`docReq-${index}`] = type.isDocumentRequired;
          obj[`comReq-${index}`] = type.isCommentRequired;
        });

        setInitialValues(obj);
        setDisputeTypes(res);
      }

      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const updateAllDisputeTypes = async (values: any) => {
    try {
      dispatch(setLoading(true));
      const updatedDisputeTypes = [...disputeTypes];
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          const [property, indexStr] = key.split("-");
          const index = parseInt(indexStr);
          if (!isNaN(index)) {
            const disputeTypeToUpdate = updatedDisputeTypes[index];
            if (property === "reason") {
              disputeTypeToUpdate.reason = values[key];
            } else if (property === "docReq") {
              disputeTypeToUpdate.isDocumentRequired = values[key];
            } else if (property === "comReq") {
              disputeTypeToUpdate.isCommentRequired = values[key];
            }

            if (typeof disputeTypeToUpdate.id === "string") {
              disputeTypeToUpdate.id = null;
            }
          }
        }
      }

      const res = await updateDispiuteTypes({
        lender: lenderName,
        body: updatedDisputeTypes,
      });

      if (res) {
        const obj: any = {};

        res.forEach((type: disputeType, index: number) => {
          obj[`reason-${index}`] = type.reason;
          obj[`docReq-${index}`] = type.isDocumentRequired;
          obj[`comReq-${index}`] = type.isCommentRequired;
        });

        setInitialValues(obj);
        setDisputeTypes(res);
      }
      dispatch(setLoading(false));
      notification("Dispute reasons updated.", "success");
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  const addReason = () => {
    try {
      const temp = {
        id: `temp${disputeTypes.length}`,
        isCommentRequired: false,
        isDocumentRequired: false,
        reason: "New Reason",
      };
      disputeTypes.push(temp);
      const obj: any = {};

      disputeTypes.forEach((type: disputeType, index: number) => {
        obj[`reason-${index}`] = type.reason;
        obj[`docReq-${index}`] = type.isDocumentRequired;
        obj[`comReq-${index}`] = type.isCommentRequired;
      });

      setInitialValues(obj);
      setDisputeTypes(disputeTypes);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  const deleteReason = (reasonId: string) => {
    try {
      const temp = disputeTypes.filter((e: disputeType) => e.id !== reasonId);
      setDisputeTypes(temp);
      const updatedInitialValues: any = {};
      temp.forEach((type: disputeType, index: number) => {
        updatedInitialValues[`reason-${index}`] = type.reason;
        updatedInitialValues[`docReq-${index}`] = type.isDocumentRequired;
        updatedInitialValues[`comReq-${index}`] = type.isCommentRequired;
      });
      setInitialValues(updatedInitialValues);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="mx-12 my-12">
      <div className="rounded-2xl border border-slate-200 p-6">
        <div className="justify-between items-center flex mb-5">
          <div className="text-black text-2xl font-medium font-['Inter'] leading-9">
            Reasons
          </div>

          <CButton
            type="outlined"
            text="Add Reason"
            icon={<AddIcon />}
            onClick={addReason}
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object()}
          enableReinitialize={true}
          onSubmit={async (value: any) => {
            updateAllDisputeTypes(value);
          }}
        >
          {({ errors }) => (
            <Form>
              <AccordionGroup size="md">
                {disputeTypes &&
                  initialValues &&
                  disputeTypes.map((e, i) => (
                    <Accordion
                      expanded={index === i}
                      onChange={(event, expanded) => {
                        setIndex(expanded ? i : null);
                      }}
                      key={e.id}
                    >
                      <AccordionSummary>{e.reason}</AccordionSummary>
                      <AccordionDetails>
                        <div className="mt-2 border rounded-b-lg px-6 pt-6 pb-2">
                          <TextAreaField
                            placeholder="Enter reason"
                            name={`reason-${i}`}
                          />
                          <div className="mt-4">
                            <CheckboxField
                              className="mt-2"
                              label="Documentation Required"
                              name={`docReq-${i}`}
                            />

                            <CheckboxField
                              className="mt-2"
                              label="Comment Required"
                              name={`comReq-${i}`}
                            />
                          </div>
                          <div className="text-end mt-4">
                            <CButton
                              type="text"
                              text="Delete"
                              color="error"
                              onClick={() => {
                                deleteReason(e.id);
                              }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </AccordionGroup>
              {disputeTypes && disputeTypes.length > 0 && (
                <div className="w-full text-end">
                  <CButton type="text" text="Save changes" submit={true} />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DispiuteReasons;
