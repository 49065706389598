interface IPageHeader {
  title: string;
  desc: string;
}

const PageHeader = ({ title, desc }: IPageHeader) => {
  return (
    <div className={`bg-[#F0F4F8] py-5 px-10`}>
      <div className="py-4">
        <h1 className="text-2xl font-semibold mb-2">{title}</h1>
        <p className="text-[#555E68] font-medium">{desc}</p>
      </div>
    </div>
  );
};

export default PageHeader;
