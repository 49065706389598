import { Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";

type DetailsHeaderType = "borrower" | "account";
type Properties = {
  type: DetailsHeaderType;
  attributes: any;
};

interface IDetailsHeader {
  borrowerName?: string;
  properties: Properties;
  viewDetails?: boolean;
  showStatus?: boolean;
  status?: string;
  openViewDetailsModal?: () => void;
}

const DetailsHeader = ({
  borrowerName,
  properties,
  viewDetails = false,
  showStatus = false,
  status = "",
  openViewDetailsModal,
}: IDetailsHeader) => {
  const navigate = useNavigate();

  return (
    <div className={`bg-[#F0F4F8] py-10 px-10`}>
      <Paper className="w-full h-48 rounded-xl">
        <div className="flex items-center w-full py-4 px-6 border-b-[1px] border-[#E0E0E0]">
          <div
            className="w-8 h-8 p-1 mr-3 bg-white rounded-[100px] border border-slate-200 flex-col justify-center items-center inline-flex hover:cursor-pointer active:bg-[#f2f8ff]"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </div>
          <div className="flex justify-between w-full">
            <h1 className="ext-zinc-900 text-2xl font-semibold leading-9">
              {borrowerName}{" "}
              {properties.type === "account" && (
                <span className="text-gray-600 text-2xl font-semibold leading-9">
                  (Account)
                </span>
              )}
            </h1>
            {viewDetails && (
              <div
                className="w-[149px] h-10 px-4 py-1 rounded-md justify-center items-center gap-2 inline-flex hover:cursor-pointer"
                onClick={openViewDetailsModal}
              >
                <RemoveRedEyeIcon className="text-sky-600 w-6 h-6 relative" />
                <div className="text-sky-600 text-sm font-semibold leading-[14px]">
                  View Details
                </div>
              </div>
            )}
            {showStatus && (
              <div className="h-10 px-4 bg-orange-400 rounded-3xl justify-center items-center gap-1.5 inline-flex">
                <div className="text-white text-sm font-semibold leading-tight">
                  {status}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center w-full py-4">
          <div className="flex justify-between w-full py-4 px-8">
            {properties.attributes.map((e: any, index: number) => {
              return (
                <div
                  className={`${
                    index !== 0 ? "border-l-[1.5px] pr-10 pl-8" : "pr-8"
                  }`}
                  key={index}
                >
                  <p
                    className={`font-semibold mb-1 ${
                      properties.type === "account" && "text-sm"
                    }`}
                  >
                    {e.name}
                  </p>
                  <span className="flex items-center gap-3">
                    <p
                      className={`${
                        e.bold ? "text-lg font-semibold" : "text-sm"
                      }`}
                    >
                      {e.value}
                    </p>
                    {e.icon && e.icon}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default DetailsHeader;
