import { AccountByBorrowerResponse } from "../models/response/accountByBorrowerResponse";
import { borrowerRequest } from "../models/request/borrowerRequest";
import { borrowersRequest } from "../models/request/borrowersRequest";
import { borrowerResponse } from "../models/response/borrowerResponse";
import { borrowersResponse } from "../models/response/borrowersResponse";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";
import { AccountByBorrowerRequest } from "../models/request/accountByBorrowerRequest";
import { EditBorrowerRequest } from "../models/request/editBorrowerRequest";

export const getBorrowers = async ({
  size,
  page,
  lender,
  filter,
  searchString,
}: borrowersRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const borrowersResponse: borrowersResponse = await createRequest(
      reqObj
    ).then((e) => e?.data.body);
    return borrowersResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getBorrower = async ({ lender, borrowerId }: borrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const borrowerResponse: borrowerResponse = await createRequest(reqObj).then(
      (e) => {
        return e?.data.body;
      }
    );
    return borrowerResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getAccountsByBorrower = async ({
  lender,
  borrowerId,
  filter,
  searchString,
  size,
  page,
}: AccountByBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const accountByBorrowerResponse: AccountByBorrowerResponse =
      await createRequest({ path, method: "get" }).then((e) => e?.data.body);

    return accountByBorrowerResponse;
  } catch (error) {
    console.log(error);
  }
};

export const updateBorrower = async ({
  lender,
  borrowerId,
  body,
}: EditBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}`;
    const res: any = await createRequest({ path, method: "put", data: body });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async ({
  lender,
  borrowerId,
  userId,
  body,
}: EditBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/user/${userId}`;
    const res: any = await createRequest({ path, method: "put", data: body });
    return res;
  } catch (error) {
    console.log(error);
  }
};
