import { Field } from "formik";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
} from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";

interface option {
  label: string;
  value: any;
}
interface ISelectField {
  name?: string;
  label?: string;
  className?: string;
  horizontal?: boolean;
  multiple?: boolean;
  errorText?: string;
  options: option[];
  rest?: any;
}

const SelectField = ({
  className,
  name,
  label,
  horizontal = false,
  multiple = false,
  errorText,
  options,
  ...rest
}: ISelectField) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: any) => (
        <FormControl>
          {horizontal ? (
            <div
              className={`h-12 px-4 py-2 rounded-md
             justify-start items-center gap-6 inline-flex`}
            >
              <div className="grow shrink basis-0 h-8 justify-start items-center">
                <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                  {label}
                </div>
              </div>
              <div className="flex-col justify-start items-start inline-flex">
                <Select
                  {...field}
                  {...rest}
                  className={className}
                  value={field.value || (multiple ? [] : "")}
                  multiple={multiple}
                  placeholder="Select..."
                  renderValue={(selected: any) => {
                    if (multiple && Array.isArray(selected)) {
                      return (
                        <Box sx={{ display: "flex", gap: "0.25rem" }}>
                          {selected.map((e: any) => (
                            <Chip key={e.value} variant="soft" color="primary">
                              {e.label}
                            </Chip>
                          ))}
                        </Box>
                      );
                    } else {
                      return selected.label;
                    }
                  }}
                  indicator={<KeyboardArrowDown />}
                  onChange={(e: any, value: any) => {
                    if (multiple && value.length > 1) {
                      if (value.includes("all") && value.indexOf("all") !== 0)
                        form.setFieldValue(name, ["all"]);
                      else {
                        value = value.filter((e: any) => e !== "all");
                        form.setFieldValue(name, value);
                      }
                    } else {
                      form.setFieldValue(name, value);
                    }
                  }}
                >
                  {options.map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            <>
              <FormLabel>{label}</FormLabel>
              <Select
                {...field}
                {...rest}
                className={className}
                value={field.value || (multiple ? [] : "")}
                multiple={multiple}
                placeholder="Select..."
                renderValue={(selected: any) => {
                  if (multiple && Array.isArray(selected)) {
                    return (
                      <Box sx={{ display: "flex", gap: "0.25rem" }}>
                        {selected.map((e: any) => (
                          <Chip key={e.value} variant="soft" color="primary">
                            {e.label}
                          </Chip>
                        ))}
                      </Box>
                    );
                  } else {
                    return selected.label;
                  }
                }}
                indicator={<KeyboardArrowDown />}
                onChange={(e: any, value: any) => {
                  if (multiple && value.length > 1) {
                    if (value.includes("all") && value.indexOf("all") !== 0)
                      form.setFieldValue(name, ["all"]);
                    else {
                      value = value.filter((e: any) => e !== "all");
                      form.setFieldValue(name, value);
                    }
                  } else {
                    form.setFieldValue(name, value);
                  }
                }}
              >
                {options.map((e) => (
                  <Option key={e.value} value={e.value}>
                    {e.label}
                  </Option>
                ))}
              </Select>
            </>
          )}

          {meta.touched && meta.error ? (
            <FormHelperText>
              <p className="text-[#C41C1C]">{errorText}</p>
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    </Field>
  );
};

export default SelectField;
