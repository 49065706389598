import { createSlice } from "@reduxjs/toolkit";

interface IUtilSlice {
  sidebarOpen: boolean;
  loading: boolean;
  skeleton: boolean;
  borrowerId: number | null;
  modalName: string | null;
  openModel: boolean;
}

const initialState: IUtilSlice = {
  sidebarOpen: false,
  borrowerId: null,
  modalName: null,
  openModel: false,
  loading: false,
  skeleton: false,
};

const authSlice = createSlice({
  name: "util",
  initialState: initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      const { sidebarOpen } = action.payload;
      state.sidebarOpen = sidebarOpen;
    },
    setBorrowerId: (state, action) => {
      state.borrowerId = action.payload;
    },
    setOpenModal: (state, action) => {
      const { modalName, openModel } = action.payload;
      state.modalName = modalName;
      state.openModel = openModel;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSkeleton: (state, action) => {
      state.skeleton = action.payload;
    },
  },
});

export const {
  setSidebarOpen,
  setBorrowerId,
  setOpenModal,
  setLoading,
  setSkeleton,
} = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentSidebarState = (state: any) => state.util.sidebarOpen;
export const selectedBorrowerId = (state: any) => state.util.borrowerId;
export const isModalOpen = (state: any) => state.util.openModel;
export const isLoading = (state: any) => state.util.loading;
export const isSkeleton = (state: any) => state.util.skeleton;
export const currentModalName = (state: any) => state.util.modalName;
