import { Card, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { isSkeleton } from "../../data/store/slices/utilSlice";

interface ITile {
  title?: string | null;
  subTitle?: string | null;
  desc?: string | null;
}

const Tile = ({ title, subTitle, desc }: ITile) => {
  const isSkeletonOn = useSelector(isSkeleton);
  return (
    <>
      {!isSkeletonOn ? (
        <Card
          variant="outlined"
          className="grow h-[10rem] p-4 shadow-md rounded-md mb-4 sm:mr-4 border-b-2 border-b-primary"
        >
          <p className="text-lg font-semibold">{title}</p>
          <p className="text-xs text-[#555E68]">{subTitle}</p>
          <h2 className="text-3xl font-bold mt-6">{desc}</h2>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className="grow h-[10rem] p-4 shadow-md rounded-md mb-4 sm:mr-4 border-b-2 border-b-primary"
        >
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={20} />
          <Skeleton animation="wave" height={50} className="mt-6" />
        </Card>
      )}
    </>
  );
};

export default Tile;
