import { collectionSettingsRequest } from "../models/request/getCollectionSettingsRequest";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";

export const getLenderConfigs = async ({
  lender,
}: collectionSettingsRequest) => {
  try {
    const path: string = `/lenders/${lender}/configs`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const configResponse: any = await createRequest(reqObj);
    return configResponse.data.body;
  } catch (error) {
    console.log(error);
  }
};

export const updateLenderConfigs = async ({
  lender,
  data,
}: collectionSettingsRequest) => {
  try {
    const path: string = `/lenders/${lender}/configs`;

    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data,
    };

    const configResponse: any = await createRequest(reqObj);
    return configResponse.data.body;
  } catch (error) {
    console.log(error);
  }
};
