import { convertToCommaSeparatedString } from "../../utils/helperFunctions";
import { dashboardRequest } from "../models/request/dashboardRequest";
import { dashboardResponse } from "../models/response/dashboardResponse";
import { createRequest } from "./axios";

export const getBoard = async (type: dashboardRequest) => {
  try {
    const path: string = `/lender/dashboard?type=${type}`;
    const res: any = await createRequest({
      path,
      method: "get",
    });

    const dashboardResponse: dashboardResponse = res.data;
    return dashboardResponse;
  } catch (error) {
    console.log(error);
  }
};

const getBoards = async (input: dashboardRequest[]) => {
  try {
    const types = convertToCommaSeparatedString(input);
    const path: string = `/lender/dashboard?type=${types}`;
    const res: any = await createRequest({
      path,
      method: "get",
    });
    const dashboardResponse: dashboardResponse = res.data;
    return dashboardResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getTiles = async () => {
  try {
    const tiles: dashboardRequest[] = [
      "total-customer-count",
      "valid-customer-count",
      "recovered-amount",
      "committed-amount",
      "expected-amount",
      "total-dept",
      "valid-dept",
      "schedule-payment",
      "total-payment",
      "intent-to-resolve",
    ];
    return getBoards(tiles);
  } catch (error) {
    console.log(error);
  }
};

export const getPieChart = async () => {
  try {
    const pieChart: dashboardRequest = "total-debt-by-merchants";
    return getBoard(pieChart);
  } catch (error) {
    console.log(error);
  }
};

export const getBarChart = async () => {
  try {
    const barChart: dashboardRequest = "total-debt-by-aging";
    return getBoard(barChart);
  } catch (error) {
    console.log(error);
  }
};

export const getLineChart = async () => {
  try {
    const lineChart: dashboardRequest = "scheduled-payments-by-due-date";
    return getBoard(lineChart);
  } catch (error) {
    console.log(error);
  }
};

export const getTable = async () => {
  try {
    const table: dashboardRequest = "customer-stage";
    return getBoard(table);
  } catch (error) {
    console.log(error);
  }
};
