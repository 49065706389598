import { Form, Formik } from "formik";
import { changeStatusSchema } from "../../../../data/validations/ValidationScemas";
import SelectField from "../../../../components/formComponents/fieldComponents/SelectField";
import { updateAccount } from "../../../../data/api/accountAPI";
import { useDispatch } from "react-redux";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";

interface IChangeStatus {
  status: number;
  accountId: string;
  lender: string;
  borrowerId: string;
  refreshAccount?: () => void;
}

interface IformValues {
  status: number | null;
}

const ChangeStatus = ({
  status,
  lender,
  accountId,
  borrowerId,
  refreshAccount,
}: IChangeStatus) => {
  const dispatch: any = useDispatch();

  const options = [
    // { label: "Created", value: 0 },
    // { label: "Pending", value: 1 },
    { label: "In Collection", value: 2 },
    // { label: "Disputed", value: 3 },
    // { label: "Pay Later", value: 4 },
    // { label: "Payment Plan Created", value: 5 },
    // { label: "Payment Plan In Progress", value: 6 },
    // { label: "Payment Plan Failed", value: 18 },
    // { label: "Collected", value: 7 },
    // { label: "Full Settlement", value: 8 },
    // { label: "Partial Settlement", value: 9 },
    // { label: "Closed", value: 10 },
    // { label: "Deleted", value: 11 },
    { label: "Withdrawn", value: 12 },
    // { label: "On Hold", value: 13 },
    { label: "Do not Contact", value: 14 },
    // { label: "Deceased", value: 15 },
    // { label: "Scheduled", value: 16 },
    // { label: "Payment In Progress", value: 17 },
  ];

  const updateStatus = async (value: IformValues) => {
    try {
      dispatch(setLoading(true));
      await updateAccount({
        lender,
        accountId,
        borrowerId,
        body: { status: value.status },
      });
      refreshAccount!();
      dispatch(setLoadingWithDelay(false));
      notification("Status updated.", "success");
      dispatch(setOpenModal({ modalName: "change_status", openModel: false }));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <div className="container flex-col justify-center items-center gap-12 inline-flex">
      <div className="flex-col justify-center items-center gap-2 flex">
        <div className="h-8 text-zinc-900 text-2xl font-semibold leading-9">
          Choose Account Status
        </div>
        <div className="text-center text-black text-sm font-medium leading-normal mb-2">
          Select the status that best represents the current state of the
          account.
        </div>
        <Formik
          initialValues={{
            status,
          }}
          validationSchema={changeStatusSchema}
          onSubmit={async (value: IformValues) => {
            updateStatus(value);
          }}
        >
          {({ errors }) => (
            <Form className="container" id="change_status">
              <SelectField
                options={options}
                name="status"
                errorText={errors.status}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeStatus;
