import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSkeleton } from "../slices/utilSlice";

export const setSkeletonWithDelay = createAsyncThunk(
  "util/setSkeletonWithDelay",
  async (payload: boolean, { dispatch }) => {
    if (payload) {
      dispatch(setSkeleton(payload));
    } else {
      setTimeout(() => {
        dispatch(setSkeleton(payload));
      }, 1000);
    }
  }
);

export const setLoadingWithDelay = createAsyncThunk(
  "util/setLoadingWithDelay",
  async (payload: boolean, { dispatch }) => {
    if (payload) {
      dispatch(setLoading(payload));
    } else {
      setTimeout(() => {
        dispatch(setLoading(payload));
      }, 1000);
    }
  }
);
