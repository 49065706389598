import FormControl from "@mui/joy/FormControl";
import { Checkbox, FormHelperText } from "@mui/joy";
import { Field } from "formik";
import { useEffect, useState } from "react";

interface ICheckboxField {
  label?: string;
  name?: string;
  className?: string;
  errorText?: string;
  horizontal?: boolean;
  redirect?: boolean;
  showLinkDefault?: boolean;
  rest?: any;
}

const CheckboxField = ({
  label,
  name,
  errorText,
  horizontal,
  className,
  ...rest
}: ICheckboxField) => {
  return (
    <div className={`${className} ${horizontal && "w-full"}`}>
      <div className="px-4 py-2 rounded-md border border-slate-300">
        <>
          <Field name={name} type="checkbox">
            {({ field, form, meta }: any) => (
              <FormControl>
                <div className={`justify-start items-center gap-6 flex`}>
                  <div className="grow shrink basis-0 justify-start items-center flex">
                    <div className="grow shrink basis-0 text-zinc-900 text-base leading-snug">
                      {label}
                    </div>
                  </div>
                  <div>
                    <Checkbox
                      key={field.value}
                      {...field}
                      {...rest}
                      name={name}
                      className={className}
                      checked={field.value}
                      onChange={(e) => {
                        form.setFieldValue(name, e.target.checked);
                        field.onChange(e);
                      }}
                    />
                  </div>
                </div>

                {meta.touched && meta.error ? (
                  <FormHelperText>
                    <p className="text-[#C41C1C]">{errorText}</p>
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          </Field>
        </>
      </div>
    </div>
  );
};

export default CheckboxField;
