import { profileSettingsRequest } from "../models/request/profileSettingsRequest";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";

export const getProfileSettings = async ({
  lender,
}: profileSettingsRequest) => {
  try {
    const path: string = `/lenders/${lender}/settings/profile`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const putProfileSettings = async ({
  lender,
  data,
}: profileSettingsRequest) => {
  try {
    const path: string = `/lenders/${lender}/settings/profile`;
    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data: data,
    };

    const res: any = await createRequest(reqObj).then((e) => e.data.body);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async ({
  lender,
  data,
}: profileSettingsRequest) => {
  try {
    const path: string = `/lenders/${lender}/settings/password`;
    const reqObj: ICreateRequest = {
      path,
      method: "post",
      data: data,
    };

    const res: any = await createRequest(reqObj);
    return res;
  } catch (error) {
    console.log(error);
  }
};
