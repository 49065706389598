import Button from "@mui/material/Button";
import IconButton from "@mui/joy/IconButton";

import { useState } from "react";
import { CircularProgress } from "@mui/material";

type buttonType = "contained" | "outlined" | "text";
type buttonVarient = "contact" | "icon-button";

interface IButton {
  className?: string;
  form?: string;
  text?: any;
  type?: buttonType;
  icon?: any[] | any;
  value?: string;
  varient?: buttonVarient;
  color?: any;
  loading?: boolean;
  onClick?: (row?: any) => void;
  submit?: boolean;
}

const CButton = ({
  className,
  form,
  text,
  type,
  icon,
  varient,
  value,
  loading,
  color,
  submit = true,
  onClick,
}: IButton) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <>
      {varient === "contact" ? (
        <Button
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`${className} capitalize`}
          variant={type}
          startIcon={hovered ? icon && icon[1] : icon && icon[0]}
          onClick={onClick}
          form={form}
        >
          {text}
        </Button>
      ) : varient === "icon-button" ? (
        <IconButton
          className={`${className} h-10 w-12 border border-[#CDD7E1] border-solid capitalize	`}
          onClick={onClick}
          form={form}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          className={`${className} h-10 capitalize`}
          variant={type}
          startIcon={icon}
          onClick={onClick}
          type={submit ? "submit" : "button"}
          form={form}
          defaultValue={value}
          color={color}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : text}
        </Button>
      )}
    </>
  );
};

export default CButton;
