import { Navigate, useLocation } from "react-router-dom";
import Layout from "../../scenes/layout";

interface IRequiresAuth {}

const RequiresAuth = (props: IRequiresAuth) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const lenderName = localStorage.getItem("lenderName");

  return (
    <>
      {token ? (
        <Layout />
      ) : (
        <Navigate
          to={`l/${lenderName && lenderName}`}
          state={location}
          replace
        />
      )}
    </>
  );
};

export default RequiresAuth;
