import CloseIcon from "@mui/icons-material/Close";
import CButton from "../formComponents/CButton";

type size = "xs" | "sm" | "md" | "lg" | "xl" | "fullscreen" | undefined;
type varient = "default" | "confirmation";

interface ICModal {
  title: string;
  open: boolean;
  icon?: any;
  setOpen: any;
  body: any;
  size?: size;
  footer?: boolean;
  closeButton?: boolean;
  closeButtonText?: string;
  closeButtonFunc?: () => void;
  actionButton?: boolean;
  actionButtonForm?: string;
  actionButtonText?: string;
  actionButtonFunc?: () => void;
  actionButtonIcon?: any;
  submit?: boolean;
  varient?: varient;
  customActionButtons?: any;
}

const CModal = ({
  open = false,
  title,
  icon,
  setOpen,
  body,
  size,
  footer = true,
  closeButton = false,
  closeButtonText = "Close",
  closeButtonFunc,
  actionButton = false,
  actionButtonText = "Submit",
  actionButtonFunc,
  actionButtonIcon,
  actionButtonForm,
  varient = "default",
  customActionButtons,
  submit,
}: ICModal) => {
  let modalSize = "";

  switch (size) {
    case "xs":
      modalSize = "w-[450px]";
      break;
    case "sm":
      modalSize = "max-w-screen-sm";
      break;
    case "md":
      modalSize = "max-w-screen-md";
      break;
    case "lg":
      modalSize = "max-w-screen-lg";
      break;
    case "xl":
      modalSize = "max-w-screen-xl";
      break;
    case "fullscreen":
      modalSize = "w-[calc(100vw-5rem)] h-[calc(100vh-5rem)]";
      break;
    default:
      modalSize = "max-w-md";
      break;
  }

  return (
    <>
      {open && varient === "default" ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div
            className="fixed inset-0 z-40 backdrop-blur-sm bg-black/30"
            onClick={() => setOpen(false)}
          ></div>
          <div
            className={`relative z-50 flex flex-col bg-white border-0 shadow-lg outline-none focus:outline-none ${modalSize} w-full`}
          >
            <div className="flex items-center justify-between py-2 px-6 border-b border-solid border-blueGray-200 bg-primarySoft">
              <div className="text-primary mr-2">{icon}</div>
              <h3 className="text-xl font-semibold text-[#32383E] flex items-center">
                {title}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <CloseIcon className="align-baseline" />
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">{body}</div>
            {footer && (
              <div className="border-t border-solid border-blueGray-200">
                <div className="flex justify-end w-full py-2 px-6">
                  {closeButton && (
                    <CButton
                      className="mr-2 shadow-md"
                      text={closeButtonText}
                      type="outlined"
                      onClick={closeButtonFunc}
                    />
                  )}

                  {actionButton && (
                    <CButton
                      text={actionButtonText}
                      type="contained"
                      onClick={actionButtonFunc}
                      icon={actionButtonIcon}
                      form={actionButtonForm}
                      submit
                    />
                  )}
                </div>
              </div>
            )}
            {customActionButtons}
          </div>
        </div>
      ) : (
        open && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div
              className="fixed inset-0 z-40 backdrop-blur-sm bg-black/30"
              onClick={() => {
                setOpen(false);
              }}
            ></div>
            <div
              className={`relative z-50 flex flex-col bg-white border-0 shadow-lg outline-none focus:outline-none ${modalSize}`}
            >
              <div className="flex items-center justify-between py-2 px-6 border-b border-solid border-blueGray-200">
                <h3 className="text-xl font-semibold text-[#32383E] flex items-center">
                  {title}
                </h3>
              </div>
              <div className="relative p-6 flex-auto">{body}</div>
              {footer && (
                <div className="flex justify-end w-full py-2 px-6">
                  {actionButton && (
                    <CButton
                      className="leading-normal tracking-wide mr-2"
                      text={actionButtonText}
                      type="text"
                      color="error"
                      onClick={actionButtonFunc}
                      icon={actionButtonIcon}
                      form={actionButtonForm}
                      submit
                    />
                  )}

                  {closeButton && (
                    <CButton
                      className="leading-normal tracking-wide"
                      text={closeButtonText}
                      type="text"
                      onClick={closeButtonFunc}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CModal;
