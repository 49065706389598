type Activity = {
  date: string;
  daysPassed: string;
  activity: string;
};
interface IActivityHistory {
  activities: Activity[];
}

const ActivityHistory = ({ activities }: IActivityHistory) => {
  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold leading-8 mb-5">Activity</h1>
      <div className="border-b-2"></div>
      <div className="h-64 overflow-auto">
        {activities.map((e) => (
          <div className="flex my-4 py-4 px-6 border border-[#F0F4F8] rounded-md shadow-sm">
            <div className="text-[#555E68] text-sm w-[6.5rem] border-r-[1.5px]">
              {e.daysPassed}
            </div>
            <div className="flex justify-between w-full">
              <div className="font-semibold text-sm  px-4">{e.activity}</div>
              <div className="text-[#555E68] text-sm border-l-[1.5px] w-[10rem] px-4">
                {e.date}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityHistory;
