import { Outlet } from "react-router-dom";
import SideNavbar from "../global/SideNavbar";
import Topbar from "../global/Topbar";
import { useSelector } from "react-redux";
import { selectCurrentSidebarState } from "../../data/store/slices/utilSlice";
import { useMediaQuery } from "react-responsive";

interface ILayout {}

const Layout = (props: ILayout) => {
  const currentSidebarState = useSelector(selectCurrentSidebarState);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <>
      {!isTabletOrMobile ? (
        <div>
          <div className={`sticky top-0 z-40`}>
            <Topbar />
          </div>
          <div className="relative lg:static flex">
            <div
              className={`${
                currentSidebarState ? "block" : "hidden"
              } lg:block lg:sticky lg:top-[76px] lg:h-fill z-50 absolute top-0 bottom-0 left-0`}
            >
              <SideNavbar />
            </div>
            <div className="flex-1 overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Alert!</strong>
            <span className="block sm:inline">
              This app is intended to be used on desktop. Please visit from a
              desktop browser.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
