import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { FormHelperText, Textarea } from "@mui/joy";
import { Field } from "formik";

interface ITextArea {
  placeholder?: string;
  label?: string;
  name?: string;
  className?: string;
  errorText?: string;
  rest?: any;
}

const TextAreaField = ({
  placeholder,
  label,
  name,
  errorText,
  className,
  ...rest
}: ITextArea) => {
  return (
    <div className={`${className}`}>
      <Field name={name}>
        {({ field, meta }: any) => (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <Textarea
              {...field}
              {...rest}
              placeholder={placeholder}
              name={name}
              minRows={5}
              error={meta.touched && meta.error ? true : false}
            />
            {meta.touched && meta.error ? (
              <FormHelperText>
                <p className="text-[#C41C1C]">{errorText}</p>
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default TextAreaField;
