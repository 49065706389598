import PageHeader from "../../../components/borrowerComponents/PageHeader";
import CTabs, { Tab } from "../../../components/tabs/CTabs";
import AllDisputes from "./allDisputes";
import DispiuteReasons from "./disputeReasons";

type Props = {};

const Disputes = (props: Props) => {
  const tabs: Tab[] = [
    {
      label: "All Disputes",
      content: <AllDisputes />,
    },
    {
      label: "Dispute Reasons",
      content: <DispiuteReasons />,
    },
  ];
  return (
    <>
      <PageHeader
        title="Disputes"
        desc="Handle disputes efficiently. View, manage, and resolve disputes with ease. Streamlining your dispute resolution process."
      />
      <CTabs tabs={tabs} />
    </>
  );
};

export default Disputes;
