import { CircularProgress } from "@mui/material";

interface ILoading {}

const Loading = (props: ILoading) => {
  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 z-[100] backdrop-blur-sm bg-black/30" />
      <div className={`relative z-[100] flex flex-col focus:outline-none`}>
        <CircularProgress size={65} color="primary" />
      </div>
    </div>
  );
};

export default Loading;
