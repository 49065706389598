import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/joy";
import { Field } from "formik";
import { useState } from "react";

interface IInputField {
  placeholder?: string;
  label?: string;
  name?: string;
  className?: string;
  password?: boolean;
  search?: boolean;
  onSearchSubmit?: (searchString: string) => void;
  errorText?: string;
  rest?: any;
}

const InputField = ({
  placeholder,
  label,
  name,
  errorText,
  className,
  onSearchSubmit,
  password = false,
  search = false,
  ...rest
}: IInputField) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <div className={`${className}`}>
      {search ? (
        <Input
          className={`h-10`}
          placeholder={placeholder}
          name={name}
          onChange={(e) => {
            setTimeout(() => {
              onSearchSubmit!(e.target.value);
            }, 2000);
          }}
        />
      ) : (
        <Field name={name}>
          {({ field, meta }: any) => (
            <FormControl>
              <FormLabel>{label}</FormLabel>
              {password ? (
                <Input
                  {...field}
                  {...rest}
                  className={`h-10`}
                  placeholder={placeholder}
                  type={showPassword ? "text" : "password"}
                  name={name}
                  error={meta.touched && meta.error ? true : false}
                  endDecorator={
                    <div
                      className="cursor-pointer"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </div>
                  }
                />
              ) : (
                <Input
                  {...field}
                  {...rest}
                  className={`h-10`}
                  placeholder={placeholder}
                  name={name}
                  error={meta.touched && meta.error ? true : false}
                />
              )}
              {meta.touched && meta.error ? (
                <FormHelperText>
                  <p className="text-[#C41C1C]">{errorText}</p>
                </FormHelperText>
              ) : null}
            </FormControl>
          )}
        </Field>
      )}
    </div>
  );
};

export default InputField;
