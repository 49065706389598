import { Form, Formik } from "formik";
import SwitchField from "../../../../components/formComponents/fieldComponents/SwitchField";
import { schedulePaymentSchema } from "../../../../data/validations/ValidationScemas";
import HorizontalInputField from "../../../../components/formComponents/fieldComponents/HorizontalInputField";
import JoyUIDatePickerField from "../../../../components/formComponents/fieldComponents/JoyUIDatePickerField";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  getAccountConfig,
  putAccountConfig,
} from "../../../../data/api/accountAPI";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch } from "react-redux";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
import { getFormattedDayJSDate } from "../../../../utils/helperFunctions";
dayjs.extend(customParseFormat);

interface ISchedulePayment {
  amount?: number;
  accountId: string;
  lender: string;
  borrowerId: string;
}

interface ISchedulePaymentConfig {
  allowed: boolean;
  redirectionLink: string;
  redirectionAllowed: boolean;
  extensionDays: number;
  maxStartDays: string;
}

interface IformValues {
  allowSchedulePayment: boolean;
  redirectLink: boolean;
  extensionDays: number;
  maxStartDays: any;
  link: string;
}

const SchedulePayment = ({
  lender,
  accountId,
  borrowerId,
  amount,
}: ISchedulePayment) => {
  const [configs, setConfigs] = useState<ISchedulePaymentConfig | null>(null);
  const dispatch: any = useDispatch();

  useEffect(() => {
    getAccountConfigSchedulePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountConfigSchedulePayment = async () => {
    try {
      dispatch(setLoading(true));

      const res: any = await getAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "schedulePayment",
      });

      if (res) {
        const formattedDate = dayjs(
          res.configs.maxStartDays,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");

        setConfigs(res.configs);
        res.configs.maxStartDays = formattedDate;

        setConfigs(res.configs);
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const saveAccountConfig = async (values: IformValues) => {
    try {
      const formattedDate = dayjs(values.maxStartDays, "DD/MM/YYYY").format(
        "DD/MM/YYYY"
      );

      configs!.allowed = values.allowSchedulePayment;
      configs!.redirectionAllowed = values.redirectLink;
      configs!.redirectionLink = values.link;
      configs!.extensionDays = values.extensionDays;
      configs!.maxStartDays = formattedDate;

      const reqBody = {
        type: "schedulePayment",
        configs,
      };

      dispatch(setLoading(true));
      await putAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "schedulePayment",
        body: reqBody,
      });
      dispatch(setLoadingWithDelay(false));
      notification("Configurations saved.", "success");
      dispatch(
        setOpenModal({
          modalName: "schedule_payment",
          openModel: false,
        })
      );
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <Formik
      initialValues={{
        allowSchedulePayment: configs ? configs.allowed : false,
        redirectLink: configs ? configs.redirectionAllowed : false,
        extensionDays: configs ? configs.extensionDays : 0,
        link: configs && configs.redirectionLink ? configs.redirectionLink : "",
        maxStartDays: configs && getFormattedDayJSDate(configs.maxStartDays),
      }}
      enableReinitialize={true}
      validationSchema={schedulePaymentSchema}
      onSubmit={async (value: IformValues) => {
        saveAccountConfig(value);
      }}
    >
      {({ errors }) => (
        <div className="container flex-col justify-center items-center gap-10 flex my-6">
          <div className="flex-col justify-center items-center gap-2.5 flex">
            <div className="flex-col justify-start items-center flex">
              <div className="text-neutral-700 text-xl font-semibold leading-[31px]">
                Amount
              </div>
              <div className="text-zinc-900 text-4xl font-bold leading-[47.88px]">
                AED {amount}
              </div>
            </div>
          </div>
          <Form className="container" id="schedule_payment">
            <div className="flex-col justify-center items-center gap-4 flex">
              <SwitchField
                label="Allow Schedule Payment"
                name="allowSchedulePayment"
                horizontal={true}
              />
              <SwitchField
                label="Redirect Link"
                name="redirectLink"
                horizontal={true}
                redirect={true}
                showLinkDefault={configs ? configs.redirectionAllowed : false}
              />
              <HorizontalInputField
                name="extensionDays"
                label="Extention Days"
                placeholder="Enter days here"
                type="number"
                errorText={errors.extensionDays}
              />
              <JoyUIDatePickerField
                name="maxStartDays"
                errorText={errors.maxStartDays?.toString()}
                horizontal
                label="Set the maximum scheduling limit for users"
              />
              {/* <div className="w-full h-12 px-4 py-2 rounded-md border border-slate-300 justify-start items-center gap-6 inline-flex">
                <div className="grow shrink basis-0 h-8 justify-start items-center flex">
                  <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                    Reset Scheduled Payment
                  </div>
                </div>
                <div className="px-2 py-0.5 rounded-md justify-center items-center gap-1.5 flex">
                  <div className="text-sky-600 text-sm font-semibold leading-[14px]">
                    Reset
                  </div>
                </div>
              </div> */}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default SchedulePayment;
