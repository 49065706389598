export enum DisputeStatus {
  OPEN = 1,
  UNDER_REVIEW = 2,
  IN_PROGRESS = 3,
  ON_HOLD = 4,
  RESOLVED = 5,
  CLOSED = 6,
  REJECTED = 7,
  WITHDRAWN = 8,
}
